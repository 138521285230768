import React, { Component } from 'reactn'
import { WithRouter } from '../../../Components'
import { Api, persistState } from '../../../Services'

class Impersonate extends Component {
  constructor (props) {
    super(props)
    this.state = {
      users: []
    }
  }

  async getUsers () {
    const res = await Api.get('/users')
    if (res.ok) {
      const ordered = res.data.sort((a, b) => {
        const surnameA = a.name.split(' ').slice(-1)[0]
        const surnameB = b.name.split(' ').slice(-1)[0]

        return surnameA.localeCompare(surnameB)
      })
      this.setState({ users: ordered })
    }
  }

  async setUser () {
    const { users, selected } = this.state
    const user = users.filter(u => u._id === selected)[0]
    await this.setGlobal({ user }, persistState)
    this.props.navigate('/child')
  }

  render () {
    const { users, selected } = this.state

    return (
      <div>
        Vuoi impersonare un utente?
        <button onClick={() => this.getUsers()}>Si</button>
        <button onClick={() => this.props.navigate('/child')}>No</button>

        {
          users && users.length > 0 &&
            <>
              <select
                value={selected}
                onChange={e => this.setState({ selected: e.target.value })}
                style={{ width: '100%', height: 40, marginBottom: 15 }}
              >
                <option value='...'>...</option>
                {
                  users && users.length > 0 && users.map((user, i) => {
                    return (
                      <option key={i} value={user._id.toString()}>{user.name}</option>
                    )
                  })
                }
              </select>
              <button onClick={() => this.setUser()}>Continua</button>
            </>
        }
      </div>
    )
  }
}

export default WithRouter(Impersonate)
