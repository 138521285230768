import React, { Component } from 'reactn'
import { HashRouter, Routes, Route } from 'react-router-dom'
import { restoreState } from './Services'
import Login from './Pages/Login/Login'
import {
  Child,
  Hours,
  Parent,
  Stats,
  Impersonate
} from './Pages/User'
import { Entrances } from './Pages/Support'
import { Admin, Baby, Users } from './Pages/Admin'

export default class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loggedIn: false,
      role: 'user',
      impersonateUser: false
    }
  }

  async componentDidMount () {
    await restoreState()
    const { loggedIn, user } = this.global
    let role = 'user'
    let impersonateUser = false
    if (user && user.role) {
      role = user.role
    }

    if (user && user.impersonateUser) {
      impersonateUser = true
    }
    this.setState({ loggedIn, role, impersonateUser })
  }

  renderApp () {
    const { role, impersonateUser } = this.state

    if (role === 'support') {
      return (
        <HashRouter>
          <Routes>
            <Route path='/' exact element={<Entrances />} />
          </Routes>
        </HashRouter>
      )
    } else if (role === 'user') {
      if (impersonateUser) {
        return (
          <HashRouter>
            <Routes>
              <Route path='/' exact element={<Impersonate />} />
              <Route path='/child' exact element={<Child />} />
              <Route path='/parent' element={<Parent />} />
              <Route path='/stats' element={<Stats />} />
              <Route path='/hours' element={<Hours />} />
            </Routes>
          </HashRouter>
        )
      } else {
        return (
          <HashRouter>
            <Routes>
              <Route path='/' exact element={<Child />} />
              <Route path='/parent' element={<Parent />} />
              <Route path='/stats' element={<Stats />} />
              <Route path='/hours' element={<Hours />} />
            </Routes>
          </HashRouter>
        )
      }
    } else if (role === 'admin') {
      return (
        <HashRouter>
          <Routes>
            <Route path='/' exact element={<Admin />} />
            <Route path='/users' element={<Users />} />
          </Routes>
        </HashRouter>
      )
    } else if (role === 'owner') {
      return (
        <HashRouter>
          <Routes>
            <Route path='/' exact element={<Baby />} />
            <Route path='/admin' element={<Admin />} />
            <Route path='/users' element={<Users />} />
          </Routes>
        </HashRouter>
      )
    } else {
      return (
        <div>
          Could not load user role. Permission denied.
        </div>
      )
    }
  }

  render () {
    const { loggedIn } = this.state
    return (
      loggedIn
        ? this.renderApp()
        : <Login postLogin={(role, impersonateUser) => this.setState({ loggedIn: true, role, impersonateUser })} />
    )
  }
}
