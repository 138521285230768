import React, { Component } from 'reactn'
import { MdPerson, MdPassword, MdKey } from 'react-icons/md'
import { Api, persistState } from '../../Services'
import { Loader } from '../../Components'
import waveup from '../../Assets/waveup.png'
import wavedown from '../../Assets/wavedown.png'
import loginbg from '../../Assets/loginbg.png'
import '../Common.css'
import './Login.css'
import { toast } from 'react-toastify'

class Login extends Component {
  constructor (props) {
    super(props)
    this.state = {
      firstLogin: false,
      disableLogin: false,
      showPassword: false
    }
  }

  async login (resetPassword) {
    this.setState({ disableLogin: true, showPassword: false })
    const { username, password, conferma } = this.state

    if (resetPassword) {
      if (password !== conferma) {
        toast('Le due password non corrispondono', { type: 'error', autoClose: 2000 })
        this.setState({ disableLogin: false })
        return
      }

      const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/
      if (!regex.test(password)) {
        toast('La password deve essere minimo lunga 8 caratteri, deve contenere almeno una lettera maiuscola e almeno un numero', { type: 'error', autoClose: 4000 })
        this.setState({ disableLogin: false })
        return
      }

      const { user } = this.state
      const res = await Api.patch(`/users/${user._id}`, { password, firstLogin: false })
      if (!res.ok) {
        toast('Impossibile resettare la password, riprovare più tardi.', { type: 'error', autoClose: 2000 })
      }
    }

    if (username === '' || password === '') {
      toast('Completare tutti i campi, poi premere login', { type: 'error', autoClose: 2000 })
    } else {
      const data = await Api.login(username, password)

      if (!data) {
        toast('Le credenziali immesse sono errate.', { type: 'error', autoClose: 2000 })
      } else {
        if (data.user.firstLogin) {
          this.setState({ firstLogin: true, user: data.user, password: '', conferma: '' })
        } else {
          await this.setGlobal({ ...data, loggedIn: true }, persistState)
          this.props.postLogin(data.user.role, data.user.impersonateUser)
        }
      }
    }
    this.setState({ disableLogin: false })
  }

  render () {
    const { firstLogin, username, password, conferma, disableLogin, showPassword } = this.state

    if (!firstLogin) {
      return (
        <div className='loginpage'>
          <div className='loginbox'>

            <img alt='' src={waveup} className='wave up' />
            <div className='loginupper'>

              <span style={{ fontSize: 28, fontWeight: 'bold', textAlign: 'center' }}>Benvenuto nel tuo portale Baby Parking 👶 💫</span><br /><br />
              <span style={{ fontWeight: '400' }}>Inserisci le tue credenziali per accedere al portale</span>

              <div className='logininputs'>
                <div className='loginitem'>
                  <div className='loginlabel'>
                    <MdPerson size={25} />
                    <span style={{ fontWeight: 'bold' }}>Username</span>
                  </div>
                  <input type='text' value={username} onChange={e => this.setState({ username: e.target.value.toLowerCase() })} />
                </div>

                <div className='loginitem'>
                  <div className='loginlabel'>
                    <MdKey size={25} />
                    <span style={{ fontWeight: 'bold' }}>Password</span>
                  </div>
                  <input type={showPassword ? 'text' : 'password'} value={password} onChange={e => this.setState({ password: e.target.value })} />
                  <MdPassword size={25} onClick={() => this.setState({ showPassword: !showPassword })} />
                </div>

                <div className='loginutils'>
                  {/* <span className='loginreset' style={{ fontWeight: 'bold' }}>Reset password</span> */}
                  <span style={{ marginRight: 150 }} />
                  {
                      !disableLogin &&
                        <div className='loginbutton' onClick={async () => await this.login()}>
                          <span>Accedi</span>
                        </div>
                    }
                  {disableLogin && <Loader />}
                </div>
              </div>
            </div>
            <img alt='' src={wavedown} className='wave down' />

            <div className='loginfooter'>
              <img alt='' src={loginbg} />
              <div className='footerscript'>
                <span style={{ fontWeight: 'bold', fontSize: 12 }}>© 2024  | Deborah Tentori, Via II Giugno 4, 12023 Caraglio | IT03506680044</span>
              </div>
            </div>

          </div>
        </div>
      )
    } else {
      return (
        <div className='loginpage'>
          <div className='loginbox'>

            <img alt='' src={waveup} className='wave up' />
            <div className='loginupper'>

              <span style={{ fontSize: 28, fontWeight: 'bold', textAlign: 'center' }}>Scegli la tua nuova password 🔐</span><br /><br />
              <span style={{ fontWeight: '400' }}>Digita la nuova password e la conferma,<br />poi premi su Continua </span>

              <div className='logininputs'>
                <div className='loginitem'>
                  <div className='loginlabel'>
                    <MdKey size={25} />
                    <span style={{ fontWeight: 'bold' }}>Password</span>
                  </div>
                  <input type={showPassword ? 'text' : 'password'} value={password} onChange={e => this.setState({ password: e.target.value })} />
                  <MdPassword size={25} onClick={() => this.setState({ showPassword: !showPassword })} />
                </div>

                <div className='loginitem'>
                  <div className='loginlabel'>
                    <MdKey size={25} />
                    <span style={{ fontWeight: 'bold' }}>Conferma</span>
                  </div>
                  <input type={showPassword ? 'text' : 'password'} value={conferma} onChange={e => this.setState({ conferma: e.target.value })} />
                  <MdPassword size={25} onClick={() => this.setState({ showPassword: !showPassword })} />
                </div>

                <div className='loginutils'>
                  <span style={{ marginRight: 150 }} />
                  {
                    !disableLogin &&
                      <div className='loginbutton' onClick={async () => await this.login(true)}>
                        <span>Continua</span>
                      </div>
                  }
                  {disableLogin && <Loader />}
                </div>
              </div>
            </div>
            <img alt='' src={wavedown} className='wave down' />

            <div className='loginfooter'>
              <img alt='' src={loginbg} />
              <div className='footerscript'>
                <span style={{ fontWeight: 'bold', fontSize: 12 }}>© 2024  | Deborah Tentori, Via II Giugno 4, 12023 Caraglio | IT03506680044</span>
              </div>
            </div>

          </div>
        </div>
      )
    }
  }
}

export default Login
