import React, { Component } from 'reactn'
import { MdPerson } from 'react-icons/md'
import { persistState, clearState, Api } from '../../../Services'
import { WithRouter } from '../../../Components'
import waveup from '../../../Assets/waveup.png'
import p from '../../../../package.json'
import '../../Common.css'
import './Child.css'
import { toast } from 'react-toastify'

class Parent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      children: [],
      name: ''
    }
  }

  arraysOfObjectsEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false

    return arr1.every((obj1, index) => {
      const obj2 = arr2[index]
      return obj1.id === obj2.id && obj1.name === obj2.name
    })
  }

  async componentDidMount () {
    const img = new window.Image()
    img.src = waveup

    const { user } = this.global

    /**
     * ---- START
     * Check if there are differences betweek local `children` array
     * and the cloud version. If yes, then proceed to logout and login again.
     */
    const _user = await Api.get(`/users/${user._id}`)
    if (_user.ok) {
      const cloudUser = _user.data

      const equalityCheck = this.arraysOfObjectsEqual(user.children, cloudUser.children)

      if (!equalityCheck) {
        toast(
          'Errore durante il caricamento dei dati del bambino. Si prega di effettuare nuovamente l\'accesso.',
          {
            type: 'error',
            autoClose: 3000,
            onClose: () => this.logout()
          }
        )
        return
      }
      await this.setGlobal({ user: { ...user, ...cloudUser } }, persistState)
      this.setState({ children: cloudUser.children || [], name: cloudUser.name })
    }
    /**
     * ---- END
     */
  }

  logout = () => {
    this.setGlobal({ loggedIn: false }, persistState)
    clearState()
    window.location.reload()
  }

  render () {
    const { children, name } = this.state

    return (
      <div className='child'>
        <div className='childpage'>
          <div className='childheader'>
            <h2 style={{ marginTop: 20 }}>Benvenuto, {name.split(' ')[0]}!</h2>
            <span>Seleziona il tuo bambino prima di continuare.</span>
            <span className='childlogout' onClick={() => this.logout()}>Logout</span>
          </div>
          <div className='childbody'>
            {
              children && children.length > 0 && children.map((c, i) => (
                <div
                  key={i}
                  className='childbutton'
                  onClick={async () => {
                    await this.setGlobal({ child: c.id }, persistState)
                    this.props.navigate('/parent')
                  }}
                >
                  <MdPerson />
                  <span>{c.name}</span>
                </div>
              ))
            }
          </div>

          <span style={{ position: 'absolute', bottom: 10, right: 20, fontWeight: 'bold', fontStyle: 'italic', zIndex: 9 }}>version {p.version}</span>
          <img alt='' src={waveup} className='wave fixedbottom' />
        </div>
      </div>
    )
  }
}

export default WithRouter(Parent)
