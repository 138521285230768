import React, { Component } from 'reactn'
import {
  MdCalendarMonth,
  MdArrowBack,
  MdHouse,
  MdFoodBank,
  MdDiscount
} from 'react-icons/md'
import Datetime from 'react-datetime'
import { Api } from '../../../Services'
import { WithRouter } from '../../../Components'
import '../../Common.css'
import './Stats.css'

class Stats extends Component {
  constructor (props) {
    super(props)
    this.state = {
      year: new Date(),
      stats: []
    }
  }

  async componentDidMount () {
    await this.fetchData(2024)
  }

  async fetchData (year) {
    const { user, child } = this.global
    const res = await Api.get('/stats', { user: user._id, year, child })
    if (res.ok && res.data) {
      this.setState({ stats: res.data })
    }
  }

  render () {
    const { year, stats } = this.state

    const getWeekday = (dateString) => {
      const date = new Date(dateString)
      const weekdays = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']
      return weekdays[date.getMonth()]
    }

    return (
      <div className='stat'>
        <div className='statpage'>
          <div className='statheader'>
            <div className='stattop' style={{ marginTop: 20 }}>
              <MdArrowBack size={24} onClick={() => this.props.navigate('/parent')} />
              <h2>Statistiche dei costi</h2>
            </div>
            <div className='statweek'>
              <span style={{ marginRight: 20 }}>Selezionare l'anno che si desidera visualizzare</span>
              <div className='statweekselector'>
                <Datetime
                  className='yearpicker'
                  dateFormat='YYYY'
                  closeOnSelect
                  value={year} onChange={(date) => {
                    this.setState({ year: date })
                    this.fetchData(date.year())
                  }}
                />
                <MdCalendarMonth />
              </div>
            </div>
          </div>

          <div className='statbody'>

            {
              stats && stats.length > 0 && stats.map((item, i) => {
                const { date, baby, meal, priceSconto } = item

                return (
                  <div key={i} className='stattimeline'>
                    <div className='statgraphic'>
                      {i === 0 && <span>{year.toISOString().substring(0, 4)}</span>}
                      <div className='statbar' />
                      <div className='circle' />
                      <div className='statbar hidden' />
                    </div>
                    <div className='statcard'>
                      <div className='statcardheader'>
                        <span className='stattitle'>{getWeekday(date)}</span>
                      </div>
                      <div className='statdetail'>
                        {
                          baby && baby.length > 0 && baby.map((item, i) => {
                            return (
                              <div key={i} className='statpricehour'>
                                <MdHouse />
                                {item.total === 0 && <span>{item.hours} ore</span>}
                                {item.total > 0 && <span>{item.hours} ore x {item.priceHour}€ = {item.total}€</span>}
                              </div>
                            )
                          })
                        }
                        <div className='statpricemeal'>
                          <MdFoodBank />
                          {meal.total === 0 && <span>{meal.count} pasti</span>}
                          {meal.total > 0 && <span>{meal.count} pasti = {meal.total}€</span>}
                        </div>
                        {
                          priceSconto && priceSconto > 0 &&
                            <div className='statpricehour'>
                              <MdDiscount />
                              <span>Prezzo Scontato: {Number(baby[0].total) - Number(priceSconto)}€</span>
                            </div>
                        }
                      </div>
                    </div>
                  </div>
                )
              })
            }
            {
              (!stats || stats.length === 0) &&
                <div>
                  <span>Non sono presenti dati per l'anno selezionato</span>
                </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default WithRouter(Stats)
