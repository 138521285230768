import React from 'react'
import { ReactComponent as Loading } from '../Assets/loading.svg'
import './css/Loader.css'

const Loader = ({ style }) => {
  return (
    <>
      <Loading className='loading' style={style} />
      <div style={{ position: 'fixed', top: 0, left: 0, zIndex: 999, width: '100%', height: '100vh', backgroundColor: '#202020aa' }} />
    </>
  )
}

export default Loader
